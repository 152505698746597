<template>
  <div>
    <LoadingPlaceholder v-if="loadingUploads" />
    <template v-else>
      <table
        v-if="uploads"
        class="defaultTable"
      >
        <colgroup>
          <col>
          <col
            class="hide-down-xxxl"
            width="100"
          >
          <col
            class="hide-down-xxl"
            width="100"
          >
          <col
            class="hide-down-xl"
            width="100"
          >
          <col
            class="hide-down-lg"
            width="75"
          >
          <col width="150">
          <col class="hide-down-md">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('videoPublisherComp.id') }}</th>
            <th class="hide-down-xxxl">
              {{ $t('priority') }}
            </th>
            <th class="hide-down-xxl">
              {{ $t('cancelable') }}
            </th>
            <th class="hide-down-xl">
              {{ $t('size') }}
            </th>
            <th class="hide-down-lg">
              {{ $t('active') }}
            </th>
            <th>{{ $t('progress') }}</th>
            <th class="hide-down-md">
              {{ $t('destination') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(upload, index) in uploads"
            :key="`videoPublisher-upload-tableRow-${ index }`"
          >
            <td class="hide-down-sm">
              {{ upload.id }}
            </td>
            <td class="hide-down-xxxl">
              {{ upload.priority }}
            </td>
            <td class="hide-down-xxl">
              <input
                :checked="upload.canCancel"
                type="checkbox"
                readonly
                disabled
              >
            </td>
            <td class="hide-down-xl">
              {{ numberConversionAdapter_bytes(upload.size).bytes }} {{ numberConversionAdapter_bytes(upload.size).unit }}
            </td>
            <td class="hide-down-lg">
              <input
                :checked="upload.uploadActive"
                type="checkbox"
                readonly
                disabled
              >
            </td>
            <td>
              <span class="progress w-100">
                <div
                  v-if="upload.progress !== 0"
                  :style="`width: ${ upload.progress }%;`"
                  :aria-valuenow="upload.progress"
                  class="progress-bar bg-success"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >{{ numberFilter_noDecimals(upload.progress) }} %</div>
                <div v-else />
              </span>
            </td>
            <td class="hide-down-md">
              {{ upload.destinationFilePath }}
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>
    </template>
  </div>
</template>

<script>
// import mixins
import { numberFilterMixin } from '@/mixins/numberFilterMixin.js';
import numberConversionAdapter from '@/adapter/numberConversionAdapter.js';

export default {
  name: 'VideoPublisherUpload',
  mixins: [
    numberFilterMixin,
    numberConversionAdapter
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      cancelUploadsSource: null,
      loadingUploads: true,
      uploadsInterval: null,
      uploads: null,
    }
  },
  async created () {
    await this.getData();
    this.createUploadsInterval();
  },
  beforeDestroy () {
    this.cancelUploadsInterval();
    this.cancelUploadsRequest();
  },
  methods: {
    createUploadsInterval () {
      this.uploadsInterval = window.setInterval(() => {
        this.getData();
      }, 2000);
    },
    cancelUploadsInterval () {
      window.clearInterval(this.uploadsInterval);
      this.uploadsInterval = null;
      this.loadingUploads = false;
    },
    cancelUploadsRequest () {
      if (this.cancelUploadsSource) {
        this.cancelUploadsSource.cancel();
      }
    },
    async getData () {
      //this.loadingUploads = true;
      this.cancelUploadsRequest();
      this.cancelUploadsSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelUploadsSource.token };
      let url = `/VideoPublisher/GetUploads/${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `?laneNumber=${ this.laneNumber }`;
      }
      await this.axios.get(url, requestConfig)
        .then((uploadResponse) => {
          if (this.axios.isCancel()) {
            return;
          }
          if (uploadResponse == null) {
            this.cancelUploadsInterval();
            return;
          }
          if (uploadResponse.data == null) {
            this.cancelUploadsInterval();
            return;
          }
          this.uploads = uploadResponse.data;
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
          this.cancelUploadsInterval();
        })
        .finally(() => {
          this.loadingUploads = false;
        });
    }
  }
}
</script>

<style scoped>
.progress {
  height: 30px;
}
</style>